'use strict';

angular.module('enervexSalesappApp').controller('ProductTypeCtrl', function($scope, ProductType, $stateParams, Flash, $state, _, Util, Product) {
	$scope.errors = {};
	$scope.itemCodeDisabled = true
	$scope.codeWarning = Util.setCodeWarning()
	$scope.ctx ={}
	$scope.disableItemCode = function(){
		if ($scope.itemCodeDisabled === true) {
			$scope.itemCodeDisabled = false
		} else {
			$scope.itemCodeDisabled = true
		}
	}
	if ($stateParams.id == "new") {
		$scope.item = {
			name: "",
			code:"",
			fields:[]
		}
		fetchDeps()
		$scope.itemCodeDisabled = false
	} else {
		ProductType.get({
			id: $stateParams.id
		}).$promise.then(function(item) {
			$scope.item = item;
			fetchDeps()
		})
	}
	function fetchDeps() {
		$scope.types = ['appliance', 'section', 'termination', 'fan', 'fitting', 'economizer']
		Product.fields().$promise.then(function(result){
			$scope.allFields = result
			$scope.fields = _.map($scope.allFields, function(field){
				var has = _.find($scope.item.fields, function(f){
					return field.name == f
				})
				if (has) {
					field.selected = true
				}
				return field
			})
		})
	}
	$scope.save = function(form) {
		$scope.submitted = true;
		if (form.$valid) {
			Flash.clear();
			var payload = _.clone($scope.item);
			payload = _.omit(payload, "__v", "$promise", "$resolved");
			payload.fields = _.pluck(_.where($scope.fields,{selected:true}), "name")
			if (!$scope.item._id) {
				ProductType.save(payload).$promise.then(function(result) {
					$scope.success = true;
					Flash.create('success', '<strong>Success!</strong> Successfully created product type.', 0, {}, true);
					$state.go("product-type", {
						id: result._id
					}, {
						inherit: false,
						reload: true
					})
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			} else {
				ProductType.update({
					id: $scope.item._id
				}, payload).$promise.then(function(result) {
					
					$scope.success = true;
					Flash.create('success', '<strong>Success!</strong> Successfully updated product type.', 0, {}, true);
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			}
		} else {
			
			$scope.errors = {};
			$scope.errors.other = 'Problem saving';
			var message = "Please fix validation errors";
			Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
		}
	};
});
